<template>
  <div>
    <v-card class="">
      <v-row class="pa-5 pb-4">
        <v-col cols="auto">
          <router-link to="/utilisateurs">
            <v-btn
              fab
              small
            >
              <v-icon>mdi-keyboard-backspace</v-icon>
            </v-btn>
          </router-link>
        </v-col>
        <v-col>
          <v-card-title
            v-if="!loading"
            primary-title
            class="title"
          >
            Editer l'utilisateur ({{ user.email }})
          </v-card-title>
        </v-col>
      </v-row>
      <v-tabs
        class="subheading"
        centered
        icons-and-text
      >
        <v-tabs-slider />
        <v-tab
          href="#tab-2"
        >
          Profil
          <v-icon>mdi-account-circle</v-icon>
        </v-tab>
        <v-tab
          href="#tab-3"
        >
          Droits
          <v-icon>mdi-format-list-checks</v-icon>
        </v-tab>
        <v-tab-item
          value="tab-2"
        >
          <div class="pa-5">
            <v-form
              ref="form"
              lazy-validation
            >
              <v-text-field
                v-model="user.nom"
                label="Nom"
                :disabled="loadingUpdate || hasReadOnlyRole"
                required
                clearable
              />
              <v-text-field
                v-model="user.prenom"
                label="Prénom"
                :disabled="loadingUpdate || hasReadOnlyRole"
                required
                clearable
              />
              <v-text-field
                v-model="user.email"
                label="E-mail"
                readonly
                class=" v-input--is-disabled"
              />
              <v-select
                v-model="user.role"
                label="Rôles"
                :disabled="loadingUpdate || hasReadOnlyRole"
                no-data-text="Aucun rôle trouvé"
                :items="user.rolesDisponibles"
                return-object
              />
              <v-switch
                v-if="!notLocal()"
                v-model="user.bloque"
                :disabled="loadingUpdate"
                :label="`Bloquer ${user.prenom} ${user.nom}`"
              />
              <v-btn
                :loading="loadingUpdate"
                :disabled="hasReadOnlyRole"
                color="success"
                class="mr-4"
                @click="validate"
              >
                Enregister
              </v-btn>
            </v-form>
          </div>
        </v-tab-item>
        <v-tab-item
          value="tab-3"
        >
          <v-card
            v-if="!loading"
            flat
            tile
          >
            <v-tabs
              grow
              class="subheading"
              centered
              icons-and-text
            >
              <v-tab
                v-for="(tabContent, name , index) in arbo"
                :key="index"
                :href="`#tab-${index}`"
              >
                {{ tabContent.label }}
                <v-icon>
                  {{ tabContent.icon }}
                </v-icon>
              </v-tab>

              <v-tab-item
                v-for="(tabContent, label, index) in arbo"
                :key="index"
                :value="'tab-' + index"
              >
                <v-card
                  flat
                  tile
                >
                  <v-expansion-panels
                    v-model="panel"
                    accordion
                  >
                    <v-expansion-panel
                      v-for="(array, name ,i) in tabContent"
                      :key="i"
                    >
                      <div
                        v-if="!(name === 'icon' || name === 'label')"
                      >
                        <v-expansion-panel-header>
                          {{ array.label }}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <EditUserRoleTable
                            :table-arbo-droits="array"
                            :user="user"
                            :disabled="hasReadOnlyRole"
                          />
                        </v-expansion-panel-content>
                      </div>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </div>
</template>
<script>
  import { mapActions, mapState } from 'vuex';
  import { ARBO_DROITS } from './../../auth/droits.js';
  import EditUserRoleTable from './editUserRole/EditUserRoleTable';

  export default {
    name: 'EditUser',
    components: {
      EditUserRoleTable,
    },
    data: () => ({
      arbo: ARBO_DROITS,
      panel: 2,
      headers: [
        {
          text: 'Action',
          value: 'action',
          groupable: false,
        },
        {
          text: 'Nom',
          value: 'nom',
          groupable: false,
        },
        {
          text: 'Effectif',
          align: 'right',
          value: 'effectif',
        },
      ],
    }),
    computed: {
      ...mapState('parametrages/editUtilisateur', {
        user: state => state.userById,
        loading: state => state.loading,
        loadingUpdate: state => state.loadingUpdate,
      }),
      hasReadOnlyRole: function () {
        return ['ROLE_NATIONAL_API_EXTERNE'].includes(this.user.role);
      },
    },
    methods: {
      ...mapActions('parametrages/editUtilisateur', [
        'updateUser',
        'activateRight',
        'deactivateRight',
      ]),
      notLocal(){
        if (this.user.role && (this.user.role.includes('DEPARTEMENTAL') || this.user.role.includes('NATIONAL'))){
          return true;
        }
      },
      validate() {
        if (this.notLocal()) {
          this.user.bloque = false;
        }
        this.updateUser(this.user);
      },
      sendUpdatedRight( droit, actif ) {
        if (actif) {
          this.activateRight({ 'droit': droit, 'user': this.user });
        } else if (!actif) {
          this.deactivateRight({ 'droit': droit, 'user': this.user });
        }
      },
    },
  };
</script>
<style lang="scss">
  .table-droits {
    .v-messages.theme--light {
      min-height: 0;
    }

    p {
      margin-bottom: 10px;
      margin-top: 6px;
    }

    .v-input--selection-controls {
      margin-top: 0;
    }
  }
</style>
