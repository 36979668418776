<template>
  <div>
    <v-data-table
      light
      :headers="headers"
      :loading="loading"
      class="elevation-1 table-droits"
      loading-text="Chargement en cours"
      no-data-text="Aucun rôle trouvé"
      :items="dataTable"
      :sort-by="['label']"
      dense
      :items-per-page="20"
    >
      <template v-slot:item.action="{ item }">
        <v-switch
          v-if="!item.effectif"
          v-model="item.actif"
          :disabled="item.effectif || item.droit === loadingUpdateRole || disabled"
          @change="sendUpdatedRight(item.droit,item.actif)"
        />
        <v-switch
          v-else
          v-model="item.actif"
          color="light-green"
          :disabled="item.effectif || item.droit === loadingUpdateRole || disabled"
          @change="sendUpdatedRight(item.droit,item.actif)"
        />
      </template>
      <template v-slot:item.effectif="{ item }">
        <p v-if="item.effectif === true">
          Droit lié au rôle
        </p>
        <p v-else>
          Droit modifiable
        </p>
      </template>
    </v-data-table>
  </div>
</template>
<script>
  import { mapState, mapActions, mapGetters } from 'vuex';
  import _ from 'lodash';
  
  export default {
    name: 'Table',
    props: {
      tableArboDroits: {
        type: Object,
        required: true,
      },
      user: {
        type: Object,
        required: true,
      },
      disabled: {
        type: Boolean,
        required: false,
      },
    },
    data: () => ({
      dataTable: [],
      headers: [
        {
          text: 'Action',
          value: 'action',
        },
        {
          text: 'Nom',
          value: 'label',
        },
          {
            text: 'Effectif',
            align: 'right',
            value: 'effectif',
          },
      ],
    }),
    computed: {
      ...mapState('parametrages/editUtilisateur', {
        loading: state => state.loading,
        loadingUpdateRole: state => state.loadingUpdateRole,
      }),
        ...mapGetters('parametrages/editUtilisateur', {
        listDroitsModifiable: 'getUserRights' }
      ),
      ...mapGetters('session', {
        userDroits: 'getDroits' }
      ),
    },
    watch: {
      listDroitsModifiable() {
        this.filterRights();
      },
    },
    created() {
      this.filterRights();
    },
    methods: {
      ...mapActions('parametrages/editUtilisateur', [
        'activateRight',
        'deactivateRight',
      ]),
      filterRights(){
        let newArboObject = _.cloneDeep(this.tableArboDroits);
        delete newArboObject.label;
        let listToCheck = this.listDroitsModifiable;
        let valuesArbo = Object.values(newArboObject);
        for (let obj in valuesArbo) {
          for (let eleActif in listToCheck){
            if (valuesArbo[obj].droit === listToCheck[eleActif].nom){
              valuesArbo[obj].actif = listToCheck[eleActif].actif;
              valuesArbo[obj].effectif = listToCheck[eleActif].effectif;
            }
          }
        }
        this.dataTable = valuesArbo.filter(item => item.actif !== undefined);
      },
      sendUpdatedRight(droit,actif) {
        if(actif){
          this.activateRight({ 'droit' : droit,'user': this.user });
        }else if (!actif){
          this.deactivateRight({ 'droit' : droit,'user': this.user });
        }
      },
    },
  };
</script>
<style lang="scss">
  .table-droits{
    .v-messages.theme--light {
      min-height: 0;
    }
     p{
      margin-bottom: 10px;
      margin-top: 6px;
    }
    .v-input--selection-controls {
      margin-top: 0;
    }
  }
</style>
