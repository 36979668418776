<template>
  <v-skeleton-loader
    :loading="loading"
    height="100%"
    :types="{ skeleton: 'list-item-avatar, divider@3,table-heading,table-tbody' }"
    type="skeleton"
    transition="scale-transition"
  >
    <EditUser />
  </v-skeleton-loader>
</template>


<script>
  import { mapState,mapActions,mapGetters } from 'vuex';
  import EditUser from '../../components/utilisateurs/EditUser';

  export default {
    name: 'UserEditContainer',
    components: {
      EditUser,
    },
    data() {
      return {};
    },
    computed: {
      ...mapState('parametrages/editUtilisateur', {
        loading: state => state.loading,
      }),
    ...mapGetters('session', [
        'getPerimetreUser',
      ]),
    },
    created() {
      this.loadUserById(this.$route.params.userId);
    },
    methods: {
      ...mapActions('parametrages/editUtilisateur', {
        loadUserById: 'loadUserById',
      }),
    },
  };
</script>
